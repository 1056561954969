(function($) {

	if( $('.vehicles__list-wrapper').length > 0 ) {

		$('.vehicles__brand').each(function(){						
			$(this).on('click', function(e){
				e.preventDefault();
				$('.vehicles__brand').removeClass('active');
				$(this).addClass('active');
				var target = $(this).attr('href');				
				$('.vehicles__models').removeClass('active');
				$(target).addClass('active');
			});
		});
	}


	$('.vehicles input[name=search]').on('keyup', function() {
		let keyword = $(this).val().trim().toLowerCase();	
		let result  = [];
		let html    = '';

		if( keyword !== '' ) {
			let words  = keyword.split(' ');  // Split the keyword into individual words
			let i      = 0;
			let limit  = 10;
			$(dynamic_faqs_search.vehicles).each(function(index, value) {				
				let isMatch = words.some(word => 
				    value.brands.toLowerCase().includes(word) || 
				    value.model.toLowerCase().includes(word)
				);
	            if (isMatch) {
	                result.push(value);
	                i++;
	                if (i == limit) {
	                    return false;
	                }
	            }
			});
		} else {
			$('.vehicles').find('.vehicles__search-result').removeClass('show');
			return;
		}

		if(result.length === 0) {
			html = '<div>Nothing found. Please use a different keyword.</div>';
			$('.vehicles__search-result').html(html).addClass('show');
			setTimeout(() => {
				$('.vehicles__search-result').removeClass('show');
			}, 3000);
		} else {
			$.each(result, function(index, value) {
				html += `<div class="vehicles__search-result-item"><a href="#" data-brand="${value.brands_id}" data-id="${value.model_id}">${value.brands} ${value.model}</a></div>`;
			});
			$(this).closest('div').find('.vehicles__search-result').html(html).addClass('show');
		}
	});

	$('.vehicles').on('click', '.vehicles__search-result-item a', function(e) {
		e.preventDefault();
		let target_brand = $(this).attr('data-brand');
		let target_id = $(this).attr('data-id');

		if($('.vehicles__model[data-id="' + target_id + '"]').length) {			

			if($('.vehicles__models[id="' + target_brand + '"]:eq(0)').is(':not(.active)')) {
				$('.vehicles__brand[href="#' + target_brand + '"]:eq(0)').trigger('click');
			}

			$([document.documentElement, document.body]).animate({
				scrollTop: ($('.vehicles__model[data-id="' + target_id + '"]:eq(0)').offset().top - 150)
			}, 1000);

			$(this).closest('.vehicles').find('.vehicles__search-result').removeClass('show');
		}
	});

	// Close search results when input field is focused
	$('.vehicles input[name=search]').on('focus', function() {
		$(this).closest('div').find('.vehicles__search-result').removeClass('show');
	});	


})(jQuery);