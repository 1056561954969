(function ($) {
	//show mobile menu
	$('.menu-toggle').on('click', function (e) {
		e.preventDefault();
    	$('.mobile-menu__wrapper').addClass('active');
    	$('body').toggleClass('menu-active');
  	});

	//hide mobile menu
  	$('.mobile-menu__close-button, .mobile-menu__outer').on('click', function () {
    	$('.mobile-menu__wrapper').removeClass('active');
    	$('.mobile-menu').removeClass('child-active');
		// $('.mobile-menu__footer').removeClass('child-active');
		$('body').removeClass('menu-active');
    	$('.mobile-menu').find('.sub-menu').removeClass('show');
  	});

	$('#mobile-menu').find('.menu-item-has-children').each(function () {
		//copy the parent menu to the bottom sub-menu to make a view all link.
		$(this).find('.sub-menu').each(function(){
			if ( !$(this).hasClass('viewall-ok') ) {		    	
				let parentMenuOriginal = $(this).prev().clone().html('View All');
				let parentMenu = $("<div />").append( parentMenuOriginal ).html();				
				$(this).append('<li class="mobile-menu__view-all">'+parentMenu+'</li>');
				$(this).addClass('viewall-ok');
			}
		});



		$(this).find('.sub-menu').each(function(){
			if (!$(this).hasClass('has-back-button')) {
				$(this).prepend('<li class="mobile-menu__back"><a href="#">Back</a></li>');
				$(this).addClass('has-back-button');
			}
		});
		
		$(this)
			.children('a')
			.on('click', function (e) {
				e.preventDefault();
				$(this).parents('ul').animate({ scrollTop: 0 }, 'fast', function() {
					$(this).addClass('child-active');
	            });
				// $('.mobile-menu__footer').addClass('child-active');
				$(this).next('.sub-menu').addClass('show');
			});
    });

  	$(document).on('click', '.mobile-menu__back', function () {
		$(this).parent('.sub-menu').removeClass('show');
		$(this).closest('ul.child-active').removeClass('child-active');
		// $('.mobile-menu__footer').removeClass('child-active');
	});
})(jQuery);
