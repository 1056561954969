import { waitForFinalEvent } from "./betterAwaits.js";

(function($) {

	if( $('.related-articles').length > 0 ) {
		var resourcesSlider = {
			init: function(){
				$('.related-articles').each(function(){
					const slider = $(this).find('.related-articles__card-wrapper');
                    const arrowWrapper = $(this).find('.related-articles__arrow-wrapper');

                    const settings = {
                        infinite: true,
                        dots: false,
                        arrows: true,
                        appendArrows: arrowWrapper,
                        adaptiveHeight: true,
                        autoplay: true,
                        autoplaySpeed: 7000,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        mobileFirst: true,
                        responsive: [
                            {
                            breakpoint: 480,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1
                            }
                            },
                            {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 1
                            }
                            },
                            {
                            breakpoint: 1079,
                            settings: {
                                slidesToShow: 3,
                                slidesToScroll: 1
                            }
                            }
                        ]
                    };

					const sl =  slider.slick(settings);
                    

					$(window).on("resize orientationchange", function () {
						waitForFinalEvent(function() {
						  if( $(window).width() < 1024 &&  !sl.hasClass('slick-initialized')) {
							resourcesSlider.init();
						  }	
						}, 500, 'resources');
					});
			 	});
				
			}
		};

		$(function() {
			resourcesSlider.init();
		});


	}
})(jQuery);