(function($) {
    $('.content-after-product-category').each(function(){
        const triggerButton = $(this).find('.content-after-product-category__read-more-trigger');
        const target        = $(this).find('.content-after-product-category__hidden-content');

        triggerButton.on('click',function(e){
            e.preventDefault();            
            target.slideToggle('fast');
            // Toggle button text between 'Read More' and 'Read Less'
            const currentText = triggerButton.text();
            triggerButton.text(currentText === 'Read More' ? 'Read Less' : 'Read More');
        })

    });
    
})(jQuery);