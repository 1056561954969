(function($) {

	if( $('.site-header__search-wrapper').length > 0 ) {
		const searchIcon      = $('.site-header__search-icon');
		const closeSearchIcon = $('.site-header__search-close-icon');
		const searchWrapper   = $('.site-header__search-wrapper');


		searchIcon.on('click', function (e){
			e.preventDefault();
			searchWrapper.addClass('open');
		});

		closeSearchIcon.on('click', function (e){
			e.preventDefault();
			searchWrapper.removeClass('open');
		});

	}
})(jQuery);